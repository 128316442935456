

































import {Vue, Component, Prop} from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import { ChartItem } from 'chart.js';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';

@Component({
    name: 'Charts'
})

export default class Charts extends Vue{
    @Prop({
        type: Array,
        required: true,
    })
    public categorias: any;
    @Prop({
        type: String,
        required: true,
    })
    public fechai: any;
    @Prop({
        type: String,
        required: true,
    })
    public fechaf: any;
    @Prop({
        type: String,
        required: true,
    })
    public campania: any;
    public labels : Array<string> = [];
    public data : Array<number> = [];
    public labelcategoria = "";
    public labelTipoLlamada = "";
    public agente = "";

    // i18n variables
    public message1 = this.$t("Graphs.msgGraph1");
    public message2 = this.$t("Graphs.msgGraph2");
    public message3 = this.$t("Graphs.msgGraph3");
    public message4 = this.$t("Graphs.msgGraph4");
    public message5 = this.$t("Graphs.msgGraph5");
    public message6 = this.$t("Graphs.msgGraph6");

    mounted(): void {
        if (localStorage.getItem('user') != null) {
            this.agente = localStorage.getItem('user') as string;
        }
        this.getChartGlobal();
        for (let item of this.categorias) {
            this.getChart(item['id_cat']);
        } 
        this.getChartTipoLlamada();
    }

    updated(): void {
        this.getChartGlobal();
        for (let item of this.categorias) {
            this.getChart(item['id_cat']);
        }
        this.getChartTipoLlamada();
    }

    // Funcion para obtener datos para gráfico resumen de categorías
    private getChartGlobal(){
        const request = internet
            .newRequest()
            .get("vistaAgente/getCards?agente=" + this.agente + "&campania="+ this.campania + '&fechai=' + this.fechai + '&fechaf=' + this.fechaf)
        
        Util.waitForPromises([request])
        .then((response) => {
            let datos = response[0].data;
            for (let item of datos){
                let itemid = Object.values(item._id) as Array<string>;	
                this.labels.push(itemid[0]);
                this.data.push(+item.Promedio)                     
            }

            const data = {
                labels: this.labels,
                datasets: [{
                    label: 'Ponderación de Categorías',
                    data: this.data,
                    fill: true,
                    backgroundColor: 'rgba(230,230,230,0.5)',
                    borderColor: 'rgb(112,179,178)',
                    pointBackgroundColor: 'rgb(112,179,178)',
                    pointBorderColor: '#7BB7AF',
                    pointHoverBackgroundColor: '#E4E4E4',
                    pointHoverBorderColor: 'rgb(126,185,182)',
                    borderWidth: 1,
                }]
            };
            const ctx = document.getElementById('myChart') as ChartItem;
            const myChart = new Chart(ctx, {
                type: 'radar',
                data: data,
                options: {
                    elements: {
                        line: {
                            borderWidth: 3
                        },
                    },
                    plugins:{
                        legend:{
                            display: false
                        }
                    },
                    aspectRatio: 1
                },
            });
        })
        .finally(() => {
            this.labels = [];
            this.data = [];
        });       
    }

    // Funcion para obtener datos para gráfico según id de categoría
    private getChart(id_cat: string){
        const request = internet
            .newRequest()
            .get("vistaAgente/getCharts?agente=" + this.agente + "&id_cat=" + id_cat + "&campania="+ this.campania + '&fechai=' + this.fechai + '&fechaf=' + this.fechaf)
        
        Util.waitForPromises([request])
        .then((response) => {
            let datos = response[0].data;
            for (let item of datos){
                let itemid = Object.values(item._id) as Array<string>;
                this.labelcategoria = itemid[1].toString();
                this.labels.push(itemid[2]);
                this.data.push(+item.Promedio)                     
            }
                        
            const data = {
                labels: this.labels,
                datasets: [{
                    label: this.labelcategoria,
                    data: this.data,
                    fill: true,
                    backgroundColor: 'rgba(230,230,230,0.5)',
                    borderColor: 'rgb(112,179,178)',
                    pointBackgroundColor: 'rgb(112,179,178)',
                    pointBorderColor: '#7BB7AF',
                    pointHoverBackgroundColor: '#E4E4E4',
                    pointHoverBorderColor: 'rgb(126,185,182)',
                    borderWidth: 1,
                }]
            };
            const ctx = document.getElementById('myChart' + id_cat) as ChartItem;
            const myChartSub = new Chart(ctx, {
                type: 'radar',
                data: data,
                options: {
                    elements: {
                        line: {
                            borderWidth: 1
                        }
                    },
                    plugins:{
                        legend:{
                            display: false
                        }
                    },
                    aspectRatio: 1
                },
            });
        })
        .finally(() => {
            this.labels = [];
            this.data = [];
        });     
    }

    // Funcion para obtener datos para gráfico cantidad tipos de llamada
    private getChartTipoLlamada(){
        const request = internet
            .newRequest()
            .get("vistaAgente/getTipoLlamada?agente=" + this.agente + "&campania="+ this.campania + '&fechai=' + this.fechai + '&fechaf=' + this.fechaf)
        
        Util.waitForPromises([request])
        .then((response) => {
            let datos = response[0].data;
            for (let item of datos){
                let itemid = Object.values(item._id) as Array<string>;
                this.labelTipoLlamada = itemid[0].toString();
                this.labels.push(itemid[0]);
                this.data.push(+item.Total)                     
            }
                        
            const data = {
                labels: this.labels,
                datasets: [{
                    label: this.labelTipoLlamada,
                    data: this.data,
                    fill: true,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 1,
                }]
            };
            
            const ctx = document.getElementById('myChart5') as ChartItem;
            const myChartSub = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: {
                    elements: {
                        line: {
                            borderWidth: 3
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins:{
                        legend:{
                            display: false
                        }
                    }
                },
            });
        })
        .finally(() => {
            this.labels = [];
            this.data = [];
        });     
    }
}
