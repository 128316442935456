


















































































































































































































































	import { Component, Watch } from 'vue-property-decorator';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import MixinMain from '@/mixing/MixinMain.vue';
	import { internet } from '@/utils/Internet';
	import { IDataTable } from '@/model/main/IDataTable';
	import { IHeaderTable } from '@/model/main/IHeaderTable';
	import { ILabels } from '@/model/labels/ILabelsEvaluacion';
	import Util from '@/utils/Util';
	import { jsPDF } from "jspdf";
	import domtoimage from 'dom-to-image';
	import Charts from "@/components/dashboardAgents/Charts.vue";
	import Card from "@/components/dashboardAgents/Cards.vue";

	@Component({
		name: 'MainDashAgentes',
		components: {
			AppContainerBase,
			Charts,
			Card,
		}
	})
    export default class MainDashAgentes extends MixinMain {
        public title = this.$t("gestionAgentes.title");
		public message = this.$t("gestionAgentes.agentes");

		public isLoading = false;
		public token = '';
		public audio_path = "";
		public agente = '';
		public audio = '';
		public ruta = '';
		public categorias = [];
		public renderCard = 100;
		public renderChart = 200;
		public messageAll = this.$t("Filters.msgAll") as string;

		// agentes
		public selectAgente = '';
		public itemsAgentes = [];
	

		// Filtro
		public menu1 = false;
		public menu2 = false;
		public date1 = "";
		public date2 = "";
		public selectcampania = this.$t("Filters.msgAll") as string;
		public selecttipo = this.$t("Filters.msgAll") as string;
		public itemscampania = [this.$t("Filters.msgAll") as string];
		public itemstipo = [this.$t("Filters.msgAll") as string];
		public campania = this.$t("Filters.msgAll") as string;
		public fechai = "";
		public fechaf = "";
		// i18n variables
		public message1 = this.$t("Filters.msgFilter1");
		public message2 = this.$t("Filters.msgFilter2");
		public message3 = this.$t("Filters.msgFilter3");
		public message4 = this.$t("Filters.msgFilter4");
		public message5 = this.$t("Filters.msgFilter5");

		// Data-table
		public rows: Array<IDataTable> = [];
        public header: Array<IHeaderTable<IDataTable>> = [];
        public rowDetails: Array<IDataTable> = [];
        public headerDetails: Array<IHeaderTable<IDataTable>> = [];
        public ilabels: Array<ILabels> = [];
        public dialog = false;     
        public dialogDetails = false; 
        public dialogEmail = false; 
        public componentKey = 0;
        public elementEmail ='';
        public elementSub = '';
        public elementMessage = '';
        public elementFolio = '';
        public elementFile = {};
        public audioName = '';
        // i18n variables
        public message1_d = this.$t("Email.msgEmail1");
        public message2_d = this.$t("Email.msgEmail2");
        public message3_d = this.$t("Email.msgEmail3");
        public message4_d = this.$t("Email.msgEmail4");
        public message5_d = this.$t("Email.msgEmail5");
        public message6_d = this.$t("Email.msgEmail6");
        public message7_d = this.$t("Email.msgEmail7");
        public message8_d = this.$t("MainTable.msgMTable1");
        public message9_d = this.$t("MainTable.msgMTable2");
        public msgDetail_d = this.$t("MainTable.msgMTable6");
        public message13_d = this.$t("DetailsTable.msgDTable1");
        public message14_d = this.$t("DetailsTable.msgDTable2");
        public message15_d = this.$t("DetailsTable.msgDTable3");
        public message16_d = this.$t("DetailsTable.msgDTable4");
        public message17_d = this.$t("DetailsTable.msgDTable5");
        public message18_d = this.$t("DetailsTable.msgDTable6");
        public msgDownload_d = this.$t("DetailsTable.msgDTable7");

        mounted(): void {
			this.getAgents();
			this.audio_path = process.env.VUE_APP_API;
			this.setDates();
			if (localStorage.getItem('user') != null) {
				this.agente = localStorage.getItem('user') as string;
			}
			if (localStorage.getItem('TOKEN') != null) {
				this.token = localStorage.getItem('TOKEN') as string;
			} 
			this.getCategs();
			this.getCampania();
			this.getTipoLlamada();
			this.getData(this.campania, this.date1, this.date2);
		}

		private getAgents() {
				const request = internet
					.newRequest()
					.get("vistaAgente/getAgentes")
				
				Util.waitForPromises([request])
				.then((response) => {
					this.itemsAgentes = response[0].data;
				})
		}
		public setAgente(event: any) {
				localStorage.setItem('user', event);
				if (localStorage.getItem('user') != null) {
					this.agente = localStorage.getItem('user') as string;
				}
		}

		private setDates() {
			let today = new Date();
			let lastMonth = new Date();
			lastMonth.setMonth(today.getMonth() - 1);
			this.date2 = Util.dateToYYYYMMDD(today);
			this.date1 = Util.dateToYYYYMMDD(lastMonth);
			this.renderCard += 1;
			this.renderChart += 1;
		}

		private getCategs() {
			const request = internet
				.newRequest()
				.get("vistaAgente/getCategs")
			
			Util.waitForPromises([request])
			.then((response) => {
				this.categorias = response[0].data;
			})
		}

		private getCampania(){
			const request = internet
				.newRequest()
				.get("vistaAgente/getCampania?agente=" + this.agente)
			
			Util.waitForPromises([request])
			.then((response) => {
				let datos = response[0].data;
				for (let item of datos){
					for (let i of item.distinctcampanias){					
						this.itemscampania.push(i)
					}	 			
				}
			})
		}

		private getTipoLlamada(){
			const request = internet
				.newRequest()
				.get("vistaAgente/getTipoLlamada?agente=" + this.agente + "&campania="+ this.campania + '&fechai=' + this.date1 + '&fechaf=' + this.date2)
			
			Util.waitForPromises([request])
			.then((response) => {
				let datos = response[0].data;
				for (let item of datos){
					let itemid = Object.values(item._id) as Array<string>;
					this.itemstipo.push(itemid[0]);
				}
			})
		}

		public filtertable() {
            let campania = this.selectcampania;
            let fecha1 = this.date1;
            let fecha2 = this.date2;
			this.renderCard += 1;
			this.renderChart += 1;
            this.getData(campania, fecha1, fecha2);
		}

		public getData(campania:string, fechai: string, fechaf: string) {
			this.dialogDetails = false;

			if (campania == '' && fechai == '' && fechaf == ''){
				campania = this.messageAll;
				fechai = '2005-01-01';
				fechaf = '2050-12-31';
			}

			const request = internet
				.newRequest()
				.get("vistaAgente/getEvaluacion?agente=" + this.agente + "&campania="+ campania + '&fechai=' + fechai + '&fechaf=' + fechaf)
			
			Util.waitForPromises([request])
			.then((response) => {
				let datos = response[0].data;
				this.ilabels = datos;
				const dataTable: Array<IDataTable> = [];
				let ptje: any = {};
				for (let item = 0; item < this.ilabels.length; item++) {
					let itemid = Object.values(this.ilabels[item]._id);			
					let folio = itemid[1];		
					let cat = itemid[5];
					ptje[cat] = {'nombre': itemid[4], 'puntaje': this.ilabels[item].Ponderacion}
					try{
						let itemidnext = Object.values(this.ilabels[item+1]._id);
						if (folio === itemidnext[1]){					
							continue;
						}
						else{
							const row: IDataTable = {};
							row['MT_Folio'] = itemid[1];
							row['FechaGestion'] = itemid[0];
							for (let pt of Object.keys(ptje)) {
								let categoria = ptje[pt]['nombre'];
								row[categoria] = ptje[pt]['puntaje'] + '%';
							}			
							row[this.msgDetail_d as string] = "";
							dataTable.push(row);
							ptje = {};
						}
					}
					catch{
						const row: IDataTable = {};
						row['MT_Folio'] = itemid[1];
						row['FechaGestion'] = itemid[0];
						for (let pt of Object.keys(ptje)) {
							let categoria = ptje[pt]['nombre'];
							row[categoria] = ptje[pt]['puntaje'] + '%';
						}
						row[this.msgDetail_d as string] = "";
						dataTable.push(row);
						ptje = {};
					}
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'MT_Folio':
								text = this.message8_d as string;
								break;
							case 'FechaGestion':
								text = this.message9_d as string;
								break;
						}
						return {
							text,
							align: 'center',
							value: key,
						};
					}) as Array<IHeaderTable<IDataTable>>;
					this.rows = dataTable;
					this.header = header;
					})
				.catch(console.log)
				.finally(() => {
					this.componentKey += 1;
				});
		}

		public getDetails(folio:string) {
			const request = internet
				.newRequest()
				.get("vistaAgente/getEvaluacionDetail?agente=" + this.agente + "&folio=" + folio)
			
			Util.waitForPromises([request])
			.then((response) => {
				let datos = response[0].data;
				this.audio = datos['audio']['Audio'];
				this.ruta = datos['audio']['Ruta'];
				this.ilabels = datos['evaluaciones'];
				const dataTable: Array<IDataTable> = [];
				for (let item of this.ilabels) {
					const row: IDataTable = {};
					let itemid = Object.values(item._id);
					row['campania'] = itemid[1];
					row['categoria'] = itemid[2];
					row['subcategoria'] = itemid[6]
					row['elemento_explicito'] = itemid[3];
					row['Encontrado'] = item.Encontrado;
					row[this.message18_d as string] = "";
					this.audioName = itemid[0];
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'campania':
								text = this.message13_d as string;
								break;
							case 'categoria':
								text = this.message14_d as string;
								break;
							case 'subcategoria':
								text = this.message15_d as string;
								break;
							case 'elemento_explicito':
								text = this.message16_d as string;
								break;
							case 'Encontrado':
								text = this.message17_d as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}) as Array<IHeaderTable<IDataTable>>;
				this.rowDetails = dataTable;
				this.headerDetails = header;
			})
			.catch(console.log)
		}


		public openDetails(item:ILabels){
			let currentFolio = item.MT_Folio;
			this.elementFolio = currentFolio;
			this.dialogDetails = true;
			this.getDetails(currentFolio)
		}

		public openEmail(item:ILabels){
			this.dialogEmail = true;
			this.elementSub = item.subcategoria         
		}

		public sendEmail() {
			this.isLoading = true;
			const request = internet
				.newRequest()
				.get("send_email?destinatario=" + this.elementEmail + "&mensaje=" + this.elementMessage + "&folio=" + this.elementFolio + "&subcategoria=" + this.elementSub + "&agente=" + this.agente)
			
			Util.waitForPromises([request])
				.then((responses) => {
					this.elementMessage = "";
					this.elementEmail = "";
					this.isLoading =false;
					this.dialogEmail = false;
			})
		}

		// Funcion para descargar reporte
		public downloadWithCSS(){
			const dashboard = document.querySelector('#detailsPDF')
			console.log(dashboard)
			domtoimage
			.toPng((dashboard)!)
			.then(function(dataUrl: any) {
				console.log(dataUrl)
				let img = new Image();
				img.src = dataUrl;
				img.onload = function () {
					let doc :any =''
					console.log("img:", img.width,  img.height)

					if (img.width > img.height) {
						doc = new jsPDF('l', 'mm', [img.width, img.height])
					}else{
						doc = new jsPDF('p', 'mm', [img.width, img.height])
					}   
					doc.addImage (img, 'jpeg', 10, 10, img.width, img.height)

					const date = new Date();
						const filename =
							"dashboard - " +
							date.getFullYear() +
							("0" + (date.getMonth() + 1)).slice(-2) +
							("0" + date.getDate()).slice(-2) +
							("0" + date.getHours()).slice(-2) +
							("0" + date.getMinutes()).slice(-2) +
							("0" + date.getSeconds()).slice(-2) +
							".pdf";
						doc.save(filename);
				};
			})
			.catch(function(error: any) {
				console.error("oops, something went wrong!", error);
			});   
		}
    }
